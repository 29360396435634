.gsui-tag-list {
  .no-tag-tips {
    color: #666;
    font-size: $font-size-sm;
    padding: 10px 10px 20px;
  }
  .key {
    padding: 10px;
    &.t-title {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .value {
    padding: 10px;
    &.t-title {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .remove {
    padding: 10px;
    width: 100px;
    &.t-title {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .add-tag-tips {
    color: #666;
    font-size: $font-size-12;
    padding: 1px 0 0 4px;
  }
}
