.gsui-select-wrap {
  .gsui-select {
    position: relative;
    width: 100%;
    &.Mui-disabled {
      .MuiSelect-select {
        background-color: #eaeded;
      }
    }
  }
  .refresh-button {
    text-align: center;
  }
}
