@mixin button-size($padding-y, $padding-x, $font-size, $border-raduis) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  border-radius: $border-raduis;
}

@mixin button-style(
  $background,
  $border,
  $color,
  $hover-background: lighten($background, 7.5%),
  $hover-border: lighten($border, 10%),
  $hover-color: $color
) {
  color: $color;
  background: $background;
  border-color: $border;
  &:hover {
    color: $hover-color;
    background: $hover-background;
    border-color: $hover-border;
  }
  // &:focus,
  // &.focus {
  //   color: $hover-color;
  //   background: $hover-background;
  //   border-color: $hover-border;
  // }
  &:disabled,
  &.disabled {
    color: $color;
    background: $background;
    border-color: $border;
  }
}

@mixin zoom-animation($direction: "top", $scaleStart: scaleY(0), $scaleEnd: scaleY(1), $origin: center top) {
  .zoom-in-#{$direction}-enter {
    opacity: 0;
    transform: $scaleStart;
  }
  .zoom-in-#{$direction}-enter-active {
    opacity: 1;
    transform: $scaleEnd;
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 100ms, opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) 100ms;
    transform-origin: $origin;
  }
  .zoom-in-#{$direction}-exit {
    opacity: 1;
  }
  .zoom-in-#{$direction}-exit-active {
    opacity: 0;
    transform: $scaleStart;
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1) 100ms, opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) 100ms;
    transform-origin: $origin;
  }
}

@mixin border-right-radius($raduis) {
  border-top-right-radius: $raduis;
  border-bottom-right-radius: $raduis;
}

@mixin border-left-radius($raduis) {
  border-top-left-radius: $raduis;
  border-bottom-left-radius: $raduis;
}
