.gsui-autocomplete-select {
  position: relative;
  .input-icon {
    position: absolute;
    color: #666;
    margin: 4px 0 0 6px;
  }

  input {
    padding: 4px 10px 4px 32px;
    border-radius: 2px;
    border: 1px solid #aab7b8;
    background-color: #fff;
    height: 32px;
    line-height: 32px;
    width: 100%;
    // width: 565px;
    // height: 36px;
    // border: 1px solid #aab7b8;
    // background: #fff;
    // line-height: 36px;
    // padding: 0 5px 0 32px;
  }
  .outer-loading {
    position: absolute;
    margin: 5px 0 0 -26px;
  }
}
