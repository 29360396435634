.gsui-status-indicator {
  .status-text {
    position: relative;
    padding-left: 22px;
    i {
      position: absolute;
      margin-left: -22px;
      margin-top: -1px;
    }
    &.passed {
      color: green;
    }
    &.success {
      color: green;
    }
    &.normal {
      color: #666;
    }
    &.pending {
      color: #666;
    }
    &.warning {
      color: #ff9900;
    }
    &.error {
      color: red;
    }
  }
}
