.gsui-tag-filter {
  .t-title {
    padding-top: 10px;
    padding-bottom: 0;
    font-weight: bold;
  }
  .key {
    padding: 5px 0px;
  }
  .value {
    padding: 5px 0px 5px 10px;
  }
  .add {
    padding: 5px 0px 5px 10px;
  }
  .t-targets {
    padding: 5px 0px;
    flex-wrap: wrap;
    .t-tag {
      background: #f1faff;
      // border: 1px solid #0073bb;
      &.t-lable {
        padding: 0.375em 0.5em;
        border: 1px solid #00a1c9;
        border-right: 0;
        .key {
          font-weight: bold;
        }
      }
      .delete {
        border: 1px solid #00a1c9;
      }
    }
    .t-operator {
      padding: 0.375em;
    }
  }
}
