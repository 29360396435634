.btn-drop-down-wrap {
  display: inline-block;
  position: relative;
  .btn-drop-down {
    padding-right: 30px;
    margin-right: 0 !important;
    .dropdown-icon {
      position: absolute;
      margin-top: -2px;
    }
  }
  .drop-down-items {
    width: 100%;
    position: absolute;
    z-index: 99;
    border: 1px solid #ccc;
    // border-top: 1px solid transparent;
    background-color: #fff;
    // box-shadow: 1px 1px 1px #888;
    text-align: left;
    .menu-item {
      cursor: pointer;
      border: 1px solid transparent;
      padding: 8px 12px;
      &:hover {
        border: 1px solid #999;
        background-color: #efefef;
      }
      // &:last-child {
      //   &:hover {
      //     border-bottom: 1px solid transparent;
      //   }
      // }
    }
  }
}
