$loading-color: #555;
.gsui-loading {
  position: relative;
  color: $loading-color;
  padding-left: 20px;
  .icon {
    position: absolute;
    color: $loading-color;
    width: 15px;
    margin: 3px 0 0 -20px;
  }
}
